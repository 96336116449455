import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, StaticImage, getImage, getSrc } from "gatsby-plugin-image"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { RichText } from "prismic-reactjs"
import styled from 'styled-components'

import LayoutUpdate from "../components/layout-update"
import Seo from "../components/seo"
import htmlSerializer from "../utils/htmlSerializer"
import HeroSection831bPlan from "../components/sections/hero-section-831b-plan"
import SectionFourPartTest from "../components/sections/section-four-part-test"
import SectionCapVsMicroTakeAction from "../components/sections/section-cap-vs-micro-take-action"
import HubspotForm from 'react-hubspot-form'

const StyledButton = styled.button`
  background: #1EA0A0;
  color: #FFFFFF;

  :hover {
    background: #35AAAA;
    color: #FFFFFF;
  }
`

const SlantedDiv = styled.div`
  transform: skew(16deg); /* Adjust the angle to slant more or less */
  transform-origin: bottom right; /* Keeps the top-right corner in place */
`

const SlantedDivWhite = styled.div`
  transform: skew(16deg); /* Adjust the angle to slant more or less */
  transform-origin: bottom right; /* Keeps the top-right corner in place */
`

const Page831bPlanTemplate = () => {

  return (
    <LayoutUpdate>
      <Seo
        title="SRA 831(b) Plan"
        description="Much like the 401(k) tax code allows an employer to set aside tax-deferred dollars for retirement, the 831(b) tax code allows a business to set aside tax-deferred dollars for underinsured and/or uninsured risks. The similarities are clear and business owners should consider the risk mitigation advantages."
        noIndex
      />

      <HeroSection831bPlan 
        title="Secure Your Business Against the Unforeseen with SRA's Expertise"
        subTitle="Discover the 831(b) Plans We Offer For Your Industry"
        buttonName="VIEW 831(b) PLAN OPTIONS"
      />

      <div className="bg-white hidden lg:block">
        <div className="relative w-full pt-8 pb-16 md:pt-16 md:pb-14">
          <div className='w-11/12 max-w-screen-xl flex items-start justify-center mx-auto space-x-16 p-2'>
            <div className="w-7/12">
              <div className="z-20 flex flex-col items-start justify-start">
                <p className='text-site-gray-dark text-3xl text-left uppercase mb-4 tracking-widest'>Your Trusted Partner</p>
                <h2 className="text-site-red text-5xl text-left font-extrabold tracking-wider mb-8">Navigate Uncertainty with Confidence</h2>
                <p className='text-base md:text-xl text-site-gray-dark text-left mb-6'>In the ever-changing landscape of business, where unforeseen challenges lurk around every corner, SRA offers a beacon of stability with our specialized 831(b) risk mitigation plans. An 831(b) Plan, also referred to as captive or microcaptive insurance, is designed to help business owners mitigate unfunded liabilities.</p>
              </div>
            </div>
            <div className="bg-white w-5/12 max-w-2xl border-2 border-site-gray-dark rounded-3xl p-8 shadow-lg mx-auto z-20">
              <HubspotForm
                region='na1'
                portalId='23182726'
                formId="dfa38579-9c57-4e76-8e3d-5fd33b0f232e"
                loading={<div className='italic'>Loading request form...</div>}
              />
            </div>
          </div>

          <div className="absolute top-0 right-0 z-0">
            <StaticImage
              src="../../images/podcasts/backgrounds/get-started-bg-comp-final.png"
              width={900}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt=""
            />
          </div>
        </div> 
      </div> 

      <div className="bg-white block lg:hidden">
        <div className="relative w-full pt-8 pb-10 md:pt-16 md:pb-14">
          <div className='w-11/12 max-w-screen-xl flex flex-col items-center justify-center mx-auto space-x-0 p-2'>
            <div className="w-full xl:w-1/2">
              <div className="z-20 flex flex-col items-center justify-center">
                <p className='text-site-gray-dark text-lg md:text-3xl text-center lg:text-left uppercase mb-4 tracking-widest'>Your Trusted Partner</p>
                <h2 className="text-site-red text-2xl md:text-5xl text-center lg:text-left font-extrabold tracking-wider mb-6">Navigate Uncertainty with Confidence</h2>
                <p className='text-base md:text-xl text-site-gray-dark text-enter lg:text-left mb-6'>In the ever-changing landscape of business, where unforeseen challenges lurk around every corner, SRA offers a beacon of stability with our specialized 831(b) risk mitigation plans.An 831(b) Plan, also referred to as captive or microcaptive insurance, is designed to help business owners mitigate unfunded liabilities.</p>
              </div>
            </div>

            <div className="bg-white w-full border-2 border-site-gray-dark rounded-3xl p-8 shadow-lg mx-auto z-20">
              <HubspotForm
                region='na1'
                portalId='23182726'
                formId="dfa38579-9c57-4e76-8e3d-5fd33b0f232e"
                loading={<div className='italic'>Loading request form...</div>}
              />
            </div>
          </div>

          <div className="absolute bottom-0 right-0 z-0">
            <StaticImage
              src="../../images/podcasts/backgrounds/get-started-bg-comp-final.png"
              width={550}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt=""
            />
          </div>
        </div> 
      </div>

      <div className="bg-white">
        <div className="relative w-full flex flex-col items-center justify-center pt-8 pb-16 md:pt-16 md:pb-16">
          <div className="absolute top-0 w-6/12 md:w-3/12 border-2 border-site-red"></div>

          <div className='w-11/12 max-w-screen-xl flex flex-col items-center justify-center mx-auto'>
            <div className="z-20 flex flex-col items-center justify-center lg:items-start lg:justify-start">
              <p className='text-site-gray-dark text-lg md:text-3xl text-center lg:text-left uppercase mb-4 tracking-widest'>Beyond Traditional Insurance</p>
              <h2 className="text-site-red text-2xl md:text-5xl text-center lg:text-left font-extrabold tracking-wider mb-6">Why Use SRA's 831(b) Plans?</h2>
              <p className='text-base md:text-xl text-site-gray-dark text-enter lg:text-left mb-6'>Tailored to support small and medium-sized businesses, our plans are designed to provide a comprehensive safety net for risks that are often overlooked by traditional insurance policies.</p>
            </div>
          </div>

          <div className='w-11/12 max-w-screen-xl grid grid-cols-1 lg:grid-cols-3 gap-6 mx-auto mt-6'>
            <div className="bg-site-green-light-80 p-6 rounded-2xl">
              <h3 className="text-site-gray-dark text-2xl text-center font-bold tracking-wider mb-6">Diversify Your Safety Nets</h3>
              <p className='text-base md:text-lg text-site-gray-dark font-semibold text-center'>With economic fluctuations and unforeseen events such as pandemics becoming the new norm, it's critical to prepare for the unexpected. Our 831(b) plans offer widespread coverage, ensuring that your business remains resilient in the face of challenges that conventional insurance might miss.</p>
            </div>

            <div className="bg-site-green-light-80 p-6 rounded-2xl">
              <h3 className="text-site-gray-dark text-2xl text-center font-bold tracking-wider mb-6">Empowerment Through Knowledge</h3>
              <p className='text-base md:text-lg text-site-gray-dark font-semibold text-center'>At SRA, we believe in empowering business owners. By educating you about the benefits and intricacies of the 
              831(b) tax code, we help you become your own Paycheck Protection Program, ensuring that your business can thrive, even amidst economic turmoil. </p>
            </div>

            <div className="bg-site-green-light-80 p-6 rounded-2xl">
              <h3 className="text-site-gray-dark text-2xl text-center font-bold tracking-wider mb-6">Proven Success, Tailored Flexibility</h3>
              <p className='text-base md:text-lg text-site-gray-dark font-semibold text-center'>Leveraged by Fortune 500 companies and now accessible to smaller enterprises, our 831(b) plans have a track record of success. We pride ourselves on customizing these plans to fit your unique business needs, making SRA not just a service, but a partner in your business's growth and sustainability.</p>
            </div>
          </div>
        </div> 
      </div> 

      <div className="relative bg-site-red py-6">
        <div className='w-11/12 max-w-screen-xl flex flex-col md:flex-row items-center justify-center space-x-6 space-y-6 mx-auto'>
          <div className="w-52 h-52 bg-white rounded-full p-2 z-10">
            <div className="w-48 h-48 flex flex-col items-center justify-center border-2 border-black rounded-full p-6">
              <h3 className="text-6xl font-bold text-center">16+</h3>
              <span className="text-base uppercase text-center">Years In Business</span>
            </div>
          </div>

          <div className="w-52 h-52 bg-white rounded-full p-2 z-10">
            <div className="w-48 h-48 flex flex-col items-center justify-center border-2 border-black rounded-full p-6">
              <h3 className="text-6xl font-bold text-center">1062</h3>
              <span className="text-base uppercase text-center">Administered Plans</span>
            </div>
          </div>

          <div className="w-52 h-52 bg-white rounded-full p-2 z-10">
            <div className="w-48 h-48 flex flex-col items-center justify-center border-2 border-black rounded-full p-6">
              <h3 className="text-6xl font-bold text-center">713</h3>
              <span className="text-base uppercase text-center">Clients Served</span>
            </div>
          </div>
        </div>

        <SlantedDiv className="absolute bg-black w-16 md:w-20 left-0 top-0 bottom-0 z-0"></SlantedDiv>
        <SlantedDivWhite className="absolute bg-white w-10 md:w-16 left-20 md:left-24 top-0 bottom-0 z-0"></SlantedDivWhite>
      </div>

      <div id="plan-options" className="bg-white">
        <div className="relative w-full flex flex-col items-center justify-center pt-20 pb-16 md:pt-24 md:pb-16">

          <div className='w-11/12 max-w-screen-xl flex flex-col items-center justify-center mx-auto'>
            <div className="z-20 flex flex-col items-center justify-center lg:items-start lg:justify-start">
              <p className='text-site-gray-dark text-lg md:text-3xl text-center lg:text-left uppercase mb-4 tracking-widest'>PLan Options For You</p>
              <h2 className="text-site-red text-2xl md:text-5xl text-center lg:text-left font-extrabold tracking-wider mb-6">What Keeps You Up At Night?</h2>
              <p className='text-base md:text-xl text-site-gray-dark text-enter lg:text-left mb-6'>Small to middle-market businesses are the backbone of the American economy. Understanding the unique risks these businesses take, SRA steps in to offer a lifeline. From healthcare to manufacturing to professional services and more, SRA 831(b) Plans provide peace of mind, ensuring entrepreneurs have the support they need to not only survive but even thrive amidst the uncertainties of the market. Learn more about some of our most poplular 831(b) Plans below.</p>
            </div>
          </div>

          <div className='w-11/12 max-w-screen-xl grid grid-cols-1 lg:grid-cols-3 gap-6 mx-auto mt-6'>
            <div className="flex flex-col items-center justify-center p-6">
              <div className="w-36 h-36 flex items-center justify-center mb-4">
                <StaticImage
                  src="../images/icons/new-831b-page-icons/brand-protection-icon.png"
                  width={130}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="icon"
                />
              </div>
              <h3 className="text-site-gray-dark text-2xl text-center font-bold tracking-wider uppercase">Brand Protection</h3>
              <div className="w-8/12 border-2 border-site-red my-4 mx-auto"></div>
              <p className='text-base md:text-lg text-site-gray-dark font-semibold text-center'>Negative media 
              damaging your brands reputation.</p>
            </div>

            <div className="flex flex-col items-center justify-center p-6">
              <div className="w-36 h-36 flex items-center justify-center mb-4">
                <StaticImage
                  src="../images/icons/new-831b-page-icons/dispute-resolution-icon.png"
                  width={130}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="icon"
                />
              </div>
              <h3 className="text-site-gray-dark text-2xl text-center font-bold tracking-wider uppercase">Dispute Resolution</h3>
              <div className="w-8/12 border-2 border-site-red my-4 mx-auto"></div>
              <p className='text-base md:text-lg text-site-gray-dark font-semibold text-center'>Legal battles with employees, customers, or contractors.</p>
            </div>

            <div className="flex flex-col items-center justify-center p-6">
              <div className="w-36 h-36 flex items-center justify-center mb-4">
                <StaticImage
                  src="../images/icons/new-831b-page-icons/data-breach-icon.png"
                  width={130}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="icon"
                />
              </div>
              <h3 className="text-site-gray-dark text-2xl text-center font-bold tracking-wider uppercase">Data Breach</h3>
              <div className="w-8/12 border-2 border-site-red my-4 mx-auto"></div>
              <p className='text-base md:text-lg text-site-gray-dark font-semibold text-center'>Cyberattacks, phishing, and ransomware attacks.</p>
            </div>

            <div className="flex flex-col items-center justify-center p-6">
              <div className="w-36 h-36 flex items-center justify-center mb-4">
                <StaticImage
                  src="../images/icons/new-831b-page-icons/business-interruption-icon.png"
                  width={130}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="icon"
                />
              </div>
              <h3 className="text-site-gray-dark text-2xl text-center font-bold tracking-wider uppercase">Business Interruption</h3>
              <div className="w-8/12 border-2 border-site-red my-4 mx-auto"></div>
              <p className='text-base md:text-lg text-site-gray-dark font-semibold text-center'>Natural disasters shutting down operations.</p>
            </div>

            <div className="flex flex-col items-center justify-center p-6">
              <div className="w-36 h-36 flex items-center justify-center mb-4">
                <StaticImage
                  src="../images/icons/new-831b-page-icons/supply-chain-interruption-icon.png"
                  width={130}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="icon"
                />
              </div>
              <h3 className="text-site-gray-dark text-2xl text-center font-bold tracking-wider uppercase">Supply Chain Interruption</h3>
              <div className="w-8/12 border-2 border-site-red my-4 mx-auto"></div>
              <p className='text-base md:text-lg text-site-gray-dark font-semibold text-center'>Supply chain breakdowns from disasters or civil unrest.</p>
            </div>

            <div className="flex flex-col items-center justify-center p-6">
              <div className="w-36 h-36 flex items-center justify-center mb-4">
                <StaticImage
                  src="../images/icons/new-831b-page-icons/custom-warranty-icon.png"
                  width={130}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="icon"
                />
              </div>
              <h3 className="text-site-gray-dark text-2xl text-center font-bold tracking-wider uppercase">Custom Warranty</h3>
              <div className="w-8/12 border-2 border-site-red my-4 mx-auto"></div>
              <p className='text-base md:text-lg text-site-gray-dark font-semibold text-center'>Honoring warranty issues on products or services.</p>
            </div>
          </div>

          <div className="flex items-center justify-center mt-8">
          <StyledButton className="w-full md:w-auto font-display text-base md:text-lg tracking-wider px-8 py-3">
            VIEW 831(b) PLAN OPTIONS
          </StyledButton>
        </div>
        </div>        
      </div>

      <div className="bg-white hidden lg:block">
        <div className="relative w-full pt-8 pb-16 md:pt-16 md:pb-14">
          <div className='w-11/12 max-w-screen-xl flex items-start justify-center mx-auto space-x-16 p-2'>
            <div className="w-7/12">
              <div className="z-20 flex flex-col items-start justify-start">
                <p className='text-site-gray-dark text-3xl text-left uppercase mb-4 tracking-widest'>What Has Worked?</p>
                <h2 className="text-site-red text-5xl text-left font-extrabold tracking-wider mb-8">Request Industry Specific Case Studies</h2>
                <p className='text-base md:text-xl text-site-gray-dark text-left mb-6'>See how innovative business owners across various industries are using SRA solutions to safeguard their companies. Request our industry-specific case studies and gain insights from real-world examples that show how our clients have tackled unique challenges and strengthened their resilience. Turn risk management into a profit center-fill out the form to receive case studies tailored to your industry.</p>
              </div>
            </div>
            <div className="bg-white w-5/12 max-w-2xl border-2 border-site-gray-dark rounded-3xl p-8 shadow-lg mx-auto z-20">
              <HubspotForm
                region='na1'
                portalId='23182726'
                formId="dfa38579-9c57-4e76-8e3d-5fd33b0f232e"
                loading={<div className='italic'>Loading request form...</div>}
              />
            </div>
          </div>

          <div className="absolute bottom-20 lg:bottom-0 left-0 z-0">
            <StaticImage
              src="../../images/podcasts/backgrounds/hosted-by-bg-comp.png"
              width={490}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt=""
            />
          </div>
        </div> 
      </div> 

      <div className="bg-white block lg:hidden">
        <div className="relative w-full pt-8 pb-10 md:pt-16 md:pb-14">
          <div className='w-11/12 max-w-screen-xl flex flex-col items-center justify-center mx-auto space-x-0 p-2'>
            <div className="w-full xl:w-1/2">
              <div className="z-20 flex flex-col items-center justify-center">
                <p className='text-site-gray-dark text-lg md:text-3xl text-center lg:text-left uppercase mb-4 tracking-widest'>What Has Worked?</p>
                <h2 className="text-site-red text-2xl md:text-5xl text-center lg:text-left font-extrabold uppercase tracking-wider mb-6">Request Industry Specific Case Studies</h2>
                <p className='text-base md:text-xl text-site-gray-dark text-enter lg:text-left mb-6'>See how innovative business owners across various industries are using SRA solutions to safeguard their companies. Request our industry-specific case studies and gain insights from real-world examples that show how our clients have tackled unique challenges and strengthened their resilience. Turn risk management into a profit center-fill out the form to receive case studies tailored to your industry.</p>
              </div>
            </div>

            <div className="bg-white w-full border-2 border-site-gray-dark rounded-3xl p-8 shadow-lg mx-auto z-20">
              <HubspotForm
                region='na1'
                portalId='23182726'
                formId="dfa38579-9c57-4e76-8e3d-5fd33b0f232e"
                loading={<div className='italic'>Loading request form...</div>}
              />
            </div>
          </div>

          <div className="absolute bottom-0 left-0 z-0">
            <StaticImage
              src="../../images/podcasts/backgrounds/hosted-by-bg-comp.png"
              width={490}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt=""
            />
          </div>
        </div> 
      </div>
    </LayoutUpdate>
  )
}

export default withPrismicPreview(Page831bPlanTemplate)